import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

const Header = ({ userType }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate(userType === 'admin' ? '/admin' : '/');
  };

  return (
    <Navbar expand="lg" className="py-3" style={{ backgroundColor: '#0a3777' }}>
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand
          className="text-white d-flex align-items-center"
          style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
        >
          {/* <img
            src="path/to/logo.png"
            alt="Logo"
            style={{ height: '40px', marginRight: '10px' }}
          /> */}
          {userType === 'admin' ? 'Admin Dashboard' : 'User Dashboard'}
        </Navbar.Brand>

        {/* Logout Button */}
        <Nav className="ms-auto">
          <button
            onClick={handleLogout}
            className="btn"
            style={{
              backgroundColor: '#5ab2de',
              color: '#FFFFFF',
              fontWeight: 'bold',
              border: 'none',
            }}
          >
            Logout
          </button>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
