import React, { useEffect, useState } from 'react';
import API from '../../services/api';
import Header from '../Header';
import Footer from '../Footer';
import UserFilesTable from './UserFilesTable';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserDashboard = () => {
  const [userFiles, setUserFiles] = useState([]);
  const [adminFiles, setAdminFiles] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchUserFiles();
    fetchAdminFiles();
  }, []);

  const fetchUserFiles = async () => {
    try {
      const { data } = await API.get('/users/files', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUserFiles(data[0]?.uploadedFiles || []);
    } catch (err) {
      toast.error(err.response?.data?.message || 'Failed to fetch user files');
    }
  };

  const fetchAdminFiles = async () => {
    try {
      const { data } = await API.get('/users/admin-files', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setAdminFiles(data);
    } catch (err) {
      toast.error(err.response?.data?.message || 'Failed to fetch admin files');
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    // Validate file type and size
    if (!file) {
      toast.error('Please select a file to upload.');
      return;
    }

    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    if (!validFileTypes.includes(file.type)) {
      toast.error('Invalid file type. Only JPEG, PNG, and PDF files are allowed.');
      return;
    }

    if (file.size > maxFileSize) {
      toast.error('File size exceeds the maximum limit of 5MB.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      await API.post('/users/upload', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('File uploaded successfully!');
      fetchUserFiles(); // Refresh the user's file list
    } catch (err) {
      toast.error(err.response?.data?.message || 'File upload failed');
    }
  };

  return (
    <div>
      <Header userType="user" />
      <Container fluid className="py-5 dashboard-container bg-light">
        <h2 className="text-center mb-4" style={{ color: "#0a3777" }}>User Dashboard</h2>
        <Row className="justify-content-center mb-5">
          <Col md={6}>
            <Form onSubmit={handleUpload} className="shadow p-4 bg-white rounded">
              <Form.Group controlId="fileInput" className="mb-3">
                <Form.Label className="text-dark">Upload a File:</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  required
                  className="form-control"
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100" style={{ background: "#0a3777" }}>
                Upload
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <h4 className="text-dark mb-3">Your Files</h4>
            <UserFilesTable files={userFiles} downloadLabel="Download Your File" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="text-dark mb-3">Admin Files</h4>
            <UserFilesTable files={adminFiles} downloadLabel="Download Admin File" />
          </Col>
        </Row>
      </Container>
      <Footer />
      {/* Toast Container */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default UserDashboard;
