import React, { useEffect, useState } from "react";
import API from "../../services/api";
import UserFilesTable from "./UserFilesTable"; // Import the UserFilesTable component
import Header from "../Header"; // Import Header component
import { Container, Card, Form, Button } from "react-bootstrap"; // Import Bootstrap components
import Footer from "../Footer"; // Import Footer component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersFiles, setUsersFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageVisible, setImageVisible] = useState(true); // State to manage image visibility

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await API.get("/admin/user-files", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setUsers(data);
      setFilteredUsers(data); // Initially, show all users
    } catch (err) {
      console.error(err.response?.data?.message || "Failed to fetch users");
    }
  };

  const fetchUsersFiles = async (userId) => {
    if (!userId) {
      toast.error("Please select a valid user");
      return;
    }

    try {
      const user = users.find((u) => u._id === userId);
      setUsersFiles(user?.uploadedFiles || []);
      setSelectedUser(user);
    } catch (err) {
      console.error("Failed to fetch files for user", err);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setFileError("No file selected");
      return;
    }

    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
    ];

    if (!validFileTypes.includes(selectedFile.type)) {
      setFileError("Invalid file type. Only JPEG, PNG, and PDF are allowed.");
      return;
    }

    setFileError(""); // Clear errors

    if (!selectedUser) {
      toast.error("Please select a user first");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await API.post(`/admin/user-files/${selectedUser._id}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("File uploaded successfully!");
      fetchUsersFiles(selectedUser._id); // Refresh files
    } catch (err) {
      console.error(err.response?.data?.message || "Failed to upload file");
    }
  };

  return (
    <>
      <Header userType="admin" />
      <div className="admin-dashboard-container">
        <Container className="my-5">
          {/* User Selection Section */}
          <Card className="mb-4 shadow-sm">
            <Card.Header
              className="text-white"
              style={{ backgroundColor: "#0a3777" }}
            >
              <h4 className="mb-0">Select User</h4>
            </Card.Header>
            <Card.Body>
              <Form.Select
                onChange={(e) => {
                  const userId = e.target.value;
                  fetchUsersFiles(userId);
                  setImageVisible(!userId); // Hide image if a user is selected
                }}
                defaultValue=""
              >
                <option value="">-- Select User --</option>
                {filteredUsers.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.name} ({user.email})
                  </option>
                ))}
              </Form.Select>

              {/* Display the image below the dropdown */}
              {imageVisible && (
                <div className="mt-3 text-center">
                  {imageVisible && (
                    <div className="mt-3 text-center">
                      <img
                        src="https://img.freepik.com/free-vector/file-set-three-isolated-compositions-flat-human-characters-holding-document-pictograms-with-office-scenery-illustration_1284-65457.jpg?t=st=1733216241~exp=1733219841~hmac=6e5f1b48e934481342b419003d87ba4cb2c2b132eabdcd59e6674d06e4607689&w=1380"
                        alt="File Illustration"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </Card.Body>
          </Card>

          {/* User Files Section */}
          {usersFiles.length > 0 && (
            <Card className="mb-4 shadow-sm">
              <Card.Header
                className="text-white"
                style={{ backgroundColor: "#0a3777" }}
              >
                <h4 className="mb-0">User Files</h4>
              </Card.Header>
              <Card.Body>
                <UserFilesTable files={usersFiles} />
              </Card.Body>
            </Card>
          )}

          {/* File Upload Section */}
          {selectedUser && (
            <Card className="mt-4 shadow-sm">
              <Card.Header
                className="text-dark"
                style={{ backgroundColor: "#5ab2de" }}
              >
                <h4 className="mb-0">Upload File for {selectedUser.name}</h4>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group controlId="fileUpload">
                    <Form.Label>Select a file to upload:</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      isInvalid={!!fileError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {fileError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="mt-3"
                    style={{ backgroundColor: "#0a3777" }}
                    onClick={handleFileUpload}
                  >
                    Upload File
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
      <Footer />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </>
  );
};

export default AdminDashboard;
