import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Import Font Awesome icons
import API from '../../services/api';
import background from '../images/background.jpg';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();

  // Validation function
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return false;
    }
    if (!password || password.length < 6) {
      toast.error('Password must be at least 6 characters long');
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const { data } = await API.post('/admin/login', { email, password });
      localStorage.setItem('token', data.token);
      toast.success('Login successful!');
      setTimeout(() => navigate('/admin-dashboard'), 2000);
    } catch (err) {
      toast.error(err.response?.data?.message || 'Login failed');
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: `url(${background})`,
          minHeight: '100vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Row className="w-50 justify-content-center">
          <Col md={6} className="bg-white p-5 rounded shadow">
            <div className="text-center mb-4">
              <img
                src="\favicon.png" // Replace with your logo's URL or path
                alt="Logo"
                style={{ maxWidth: "150px", height: "auto" }}
              />
            </div>
            <h2 className="text-center mb-4">Admin Login</h2>
            <form onSubmit={handleSubmit}>
              {/* Email input */}
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              {/* Password input with eye icon */}
              <Form.Group className="mb-3 position-relative">
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  className="position-absolute"
                  style={{
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color: '#000',
                  }}
                  onClick={() => setShowPassword((prev) => !prev)} // Toggle visibility
                />
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                className="w-100"
                style={{
                  fontWeight: 'bold',
                  background: '#0a3777',
                }}
              >
                Login
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminLogin;
