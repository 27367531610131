import React, { useState } from "react";
import { Pagination } from "react-bootstrap";

const UserFilesTable = ({ files }) => {
  const backendUrl = "https://api.redeemtax.com";

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 5; // Files per page

  // Calculate indices for slicing files array
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

  // Total pages
  const totalPages = Math.ceil(files.length / filesPerPage);

  // Page change handler
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div
      className="user-files-table p-3 mb-4"
      style={{
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h3
        className="mb-3"
        style={{
          color: "#0a3777",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Uploaded Files
      </h3>
      <table
        className="table table-bordered table-hover table-striped"
        style={{ borderColor: "#5ab2de" }}
      >
        <thead style={{ background: "#0a3777", color: "#ffffff" }}>
          <tr>
            <th style={{ textAlign: "center" }}>File Name</th>
            <th style={{ textAlign: "center" }}>Created Time</th>
            <th style={{ textAlign: "center" }}>Download</th>
          </tr>
        </thead>
        <tbody>
          {currentFiles.length > 0 ? (
            currentFiles.map((file, index) => (
              <tr key={index} style={{ color: "#0a3777",textAlign:"center" }}>
                <td className="text-break">{file.fileName}</td>
                <td>{new Date(file.created_At).toLocaleString()}</td>
                <td className="text-center">
                  <a
                    href={`${backendUrl}/download/${file.fileName}`}
                    className="btn btn-sm"
                    style={{
                      background: "#5ab2de",
                      color: "#ffffff",
                      border: "none",
                      fontWeight: "bold",
                    }}
                    download={file.fileName}
                  >
                    Download
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="3"
                className="text-center"
                style={{ color: "#999", fontStyle: "italic" }}
              >
                No files available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination className="justify-content-center mt-3">
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber + 1 === currentPage}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default UserFilesTable;
