import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import API from "../../services/api";
import { Container, Row, Col, Button } from "react-bootstrap";
import background from "../images/background.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const navigate = useNavigate();

  // Validation function
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }
    if (!password || password.length < 6) {
      toast.error("Password must be at least 6 characters long!");
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const { data } = await API.post("/users/login", { email, password });
      localStorage.setItem("token", data.token);
      toast.success("Login successful! Redirecting...");
      setTimeout(() => navigate("/user-dashboard"), 2000);
    } catch (err) {
      toast.error(err.response?.data?.message || "Login failed. Please try again.");
    }
  };

  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <ToastContainer position="top-right" autoClose={3000} />
      <Row className="w-100 justify-content-center" style={{ maxWidth: "1000px" }}>
        <Col xs={10} sm={8} md={6} className="bg-white p-4 p-md-5 rounded shadow">
          <div className="text-center mb-4">
            <img
              src="\favicon.png" // Replace with your logo's URL or path
              alt="Logo"
              style={{ maxWidth: "150px", height: "auto" }}
            />
          </div>
          <h3 className="text-center mb-3">Sign in to your account</h3>
          <p className="text-center text-muted mb-4">
            Enter your email & password to login
          </p>
          <form onSubmit={handleSubmit}>
            {/* Email input */}
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Password input with eye icon */}
            <div className="mb-3 position-relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle between text and password
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                className="position-absolute"
                style={{
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setShowPassword((prev) => !prev)} // Toggle visibility
              />
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between gap-3">
              {/* Login button */}
              <Button
                type="submit"
                className="flex-grow-1"
                variant="primary"
                style={{
                  backgroundColor: "#0a3777",
                  border: "none",
                  fontWeight: "bold",
                }}
              >
                Login
              </Button>
              {/* Register button */}
              <Button
                type="button"
                className="flex-grow-1"
                variant="outline-primary"
                style={{
                  fontWeight: "bold",
                  color: "#0a3777",
                  border: "1px solid #0a3777",
                }}
                onClick={() => navigate("/register")}
              >
                Register
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
