import React, { useState } from "react";
import { Pagination } from "react-bootstrap";

const UserFilesTable = ({ files, downloadLabel }) => {
  const backendUrl = "https://api.redeemtax.com";

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 5; // Number of files per page

  // Calculate the indices for slicing the files array
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

  // Total number of pages
  const totalPages = Math.ceil(files.length / filesPerPage);

  // Change page handler
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div
      className="user-files-table mb-4"
      style={{
        backgroundColor: "transparent",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <h3
        className="mb-3"
        style={{
          color: "#0a3777",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {downloadLabel}
      </h3>
      <table
        className="table table-bordered table-hover table-striped"
        style={{
          backgroundColor: "#ffffff",
          border: `2px solid #5ab2de`,
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "#5ab2de", color: "#ffffff" }}>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "#5ab2de",
                fontWeight: "bold",
              }}
            >
              File Name
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "#5ab2de",
                fontWeight: "bold",
              }}
            >
              {downloadLabel}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentFiles.length > 0 ? (
            currentFiles.map((file, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#e5f6fc" : "#ffffff",
                }}
              >
                <td
                  className="text-break"
                  style={{
                    color: "#0a3777",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {file.fileName}
                </td>
                <td style={{ textAlign: "center" }}>
                  <a
                    href={`${backendUrl}/download/${file.fileName}`}
                    className="btn btn-sm"
                    style={{
                      backgroundColor: "#5ab2de",
                      color: "#ffffff",
                      fontWeight: "bold",
                      border: "none",
                      padding: "5px 10px",
                    }}
                    download={file.fileName}
                  >
                    Download
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="2"
                className="text-center text-muted"
                style={{
                  color: "#5ab2de",
                  fontStyle: "italic",
                }}
              >
                No files available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination className="justify-content-center mt-3">
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber + 1 === currentPage}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default UserFilesTable;
