import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
  <footer className="footer text-white py-4 mt-auto" style={{ backgroundColor: '#0a3777' }}>
    <Container>
      <Row>
        {/* Company Information */}
        <Col md={4}>
          <h5 className="text-uppercase text-white">About Us</h5>
          <p>
            File Management System helps you securely manage and share your files with ease. We ensure data privacy and efficient file handling for our users.
          </p>
        </Col>

        {/* Quick Links */}
        <Col md={4}>
          <h5 className="text-uppercase text-white">Quick Links</h5>
          <ul className="list-unstyled">
            <li>
              <a href="/#" className="text-white text-decoration-none hover-link">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/#" className="text-white text-decoration-none hover-link">
                Terms of Service
              </a>
            </li>
          </ul>
        </Col>

        {/* Contact Information */}
        <Col md={4}>
          <h5 className="text-uppercase text-white">Contact Us</h5>
          <p>Email: <a href="/#" className="text-white hover-link">support@filemanagement.com</a></p>
          <p>Phone: <a href="/#" className="text-white hover-link">+1 (800) 123-4567</a></p>
          <p>Address: 123 File St., Tech City, USA</p>
        </Col>
      </Row>
      <hr className="border-light" />
      <Row>
        <Col className="text-center">
          <p className="mb-0">© 2024 File Management System. All rights reserved.</p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
