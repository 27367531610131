import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import API from "../../services/api";
import background from "../images/background.jpg";
import "bootstrap/dist/css/bootstrap.min.css"; // Conditionally import Bootstrap

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Password visibility state
  const navigate = useNavigate();

  // Validation function
  const validateForm = () => {
    if (!name.trim()) {
      toast.error("Name is required");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return false;
    }
    if (!password || password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await API.post("/users/register", { name, email, password });
      toast.success("Registration successful!");
      setTimeout(() => navigate("/"), 2000);
    } catch (err) {
      toast.error(err.response?.data?.message || "Registration failed. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          className="card shadow p-4"
          style={{
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <h2 className="text-center mb-4 text-black">Create Account</h2>
          <form onSubmit={handleSubmit}>
            {/* Name input */}
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            {/* Email input */}
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {/* Password input with eye icon */}
            <div className="form-group mb-4 position-relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle between text and password
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FontAwesomeIcon
                icon={showPassword ?  faEye: faEyeSlash}
                className="position-absolute"
                style={{
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setShowPassword((prev) => !prev)} // Toggle visibility
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100"
              style={{ background: "#0a3777" }}
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
